const createBackendPayload = (consentTemplate, newVersion) => {
  const processedText = newVersion.consentText
    .replace(/<\/?p[^>]*>/g, "")
    .replace(/&nbsp;/g, "")
    .replace(/&amp;/g, "&");

  return {
    consentTemplateType: {
      consentTemplateTypeId: consentTemplate.consentTemplateTypeId,
      description:
        consentTemplate?.description || consentTemplate.consentTemplateType,
    },
    country: {
      countryCode: consentTemplate.countryCode,
      countryName: consentTemplate.countryName || consentTemplate.country,
    },
    language: {
      languageCode: consentTemplate.languageCode,
      languageName: consentTemplate.languageName || consentTemplate.language,
    },
    templateVersionNumber: newVersion.templateVersionNumber,
    consentText: `<p class='crs-consent'>${processedText}</p>`,
    noticeText: `<div class='crs-notice'>${newVersion.noticeText}</div>`,
  };
};

export default createBackendPayload;
